document.addEventListener("DOMContentLoaded", () => {
  const accordion = () => {
    const btns = document.querySelectorAll('.js-accordion-btn'),
          itemsSecond = document.querySelectorAll('.js-second-item');
  
    btns.forEach((btn) => {
      const parentItem = btn.closest('.js-accordion-item');
  
      btn.addEventListener('click', () => {
        const itemSiblings = getSiblings(parentItem);
        const isActive = parentItem.classList.contains('fixed'),
              content = parentItem.querySelector('.accordion__content');
  
        // show/hide accordion-content
        // itemSiblings.forEach((itemSibling) => {
        //   itemSibling.classList.remove('active');
        // });
  
        // if(isActive){
        //   parentItem.classList.remove('active');
        //   parentItem.classList.remove('fixed');
        // }else{
        //   parentItem.classList.add('active');
        //   parentItem.classList.add('fixed');
        // }
  
        if (!event.target.closest('.accordion__column--checkbox')) {
          $(content).slideToggle();
          parentItem.classList.toggle('fixed');
        }
  
  
  
  
        // scroll to accordion-content
        const btnCoords = getCoords(btn);
  
        // window.scroll({
        //   top: btnCoords.top - 100,
        //   behavior: 'smooth'
        // });
      });
  
      function checkSize () {
  
        if (window.screen.width <= 980) {
          itemsSecond.forEach(item => {
            item.classList.remove('fixed');
            item.classList.remove('active');
          })
        }
      }
      
      window.addEventListener('resize', checkSize);
      
      checkSize();
      // parentItem.querySelector('.js-arrow').addEventListener('mouseenter', event => {
      //   if (!parentItem.classList.contains('fixed')) {
      //     parentItem.classList.add('active');
      //   }
      // });
  
      // parentItem.querySelector('.js-arrow').addEventListener('mouseleave', event => {
      //   if (!parentItem.classList.contains('fixed')) {
      //     parentItem.classList.remove('active');
      //   }
      // });
  
    });
  };
  
  
  
  const carousel = () => {
    const sliders = document.querySelectorAll('.js-carousel');
  
    sliders.forEach((slider) => {
      const section = slider.closest('.js-carousel-section');
      const container = section.querySelector('.js-carousel-container');
      const nav = section.querySelector('.js-carousel-nav');
      const sectionContainer = slider.closest('.portfolio__container');
      let options;
      const arrows = section.querySelector('.js-carousel-nav');
      const items = section.querySelectorAll('.carousel__li').length;
      const arr = [
        {
          size: 320,
          amount: 1,
        },
        {
          size: 370,
          amount: 2,
        },
        {
          size: 570,
          amount: 3,
        },
        {
          size: 770,
          amount: 4,
        },
        {
          size: 1065,
          amount: 5,
        },
        {
          size: 1430,
          amount: 6,
        },
        {
          size: 1880,
          amount: 7,
        },
      ];
  
      const checkAnswer = () => {
        let answer = 'check';
  
        arr.forEach((notUse, i) => {
          let item = arr[arr.length - i - 1];
  
          if (window.innerWidth >= item.size && answer === 'check') {
            answer = item.amount < items;
          }
        });
  
        if (!answer) {
          options = {
            dots: false,
            infinite: false,
            draggable: false,
            speed: 400,
            variableWidth: true,
            appendArrows: $(nav),
            prevArrow: '<button type="button" class="carousel__nav-btn carousel__nav-btn--prev"><svg class="icon icon-arrow-prev carousel__nav-icon"><use xlink:href="#arrow-prev"></use></svg></button>',
            nextArrow: '<button type="button" class="carousel__nav-btn carousel__nav-btn--next"><svg class="icon icon-arrow-next carousel__nav-icon"><use xlink:href="#arrow-next"></use></svg></button>',
          };
  
          arrows.classList.add('hidden');
        }
        else {
          options = {
            dots: false,
            infinite: false,
            speed: 400,
            variableWidth: true,
            appendArrows: $(nav),
            prevArrow: '<button type="button" class="carousel__nav-btn carousel__nav-btn--prev"><svg class="icon icon-arrow-prev carousel__nav-icon"><use xlink:href="#arrow-prev"></use></svg></button>',
            nextArrow: '<button type="button" class="carousel__nav-btn carousel__nav-btn--next"><svg class="icon icon-arrow-next carousel__nav-icon"><use xlink:href="#arrow-next"></use></svg></button>',
            autoplay: false,
            autoplaySpeed: 5000
          };
  
          arrows.classList.remove('hidden');
        }
  
        $(slider).slick(options);
      };
  
      const updateContainerWidth = () => {
        const updatedContainerWidth = sectionContainer.getBoundingClientRect().right;
        container.setAttribute('style', `width: ${updatedContainerWidth}px`);
      };
  
      updateContainerWidth();
  
  
  
      window.addEventListener('resize', () => {
        updateContainerWidth();
        $(slider).slick('unslick');
        checkAnswer();
      });
  
      checkAnswer();
    });
  
    $('.js-slider').slick({
      infinite: true,
      arrows: false,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000
    })
  };
  
  function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
  }
  
  function calculator () {
    const wrappers = document.querySelectorAll('.js-main-item'),
          counterWrapper = document.querySelector('.js-counter'),
          mainCounterWrapper = counterWrapper.querySelector('.js-count'),
          mainCostWrapper = counterWrapper.querySelector('.js-cost'),
          topCost = document.querySelector('.js-all-cost'),
          list = document.querySelector('.js-list-calc'),
          title = document.querySelector('.js-title-footer'),
          inputCost = document.querySelector('.js-input-cost>input'),
          inputPick = document.querySelector('.js-input-pick>input'),
          tabs = document.querySelectorAll('.js-accordion-module');
  
    tabs.forEach (tab => {
      let btn = tab.querySelector('.js-btn'),
          text = tab.querySelector('.js-text');
  
      btn.addEventListener('click', event => {
        if (tab.classList.contains('active')) {
          tab.classList.remove('active');
          text.textContent = 'Выбрать';
          tabs.forEach((item, i) => {
            if (item === tab) {
              wrappers[i].classList.add('inactive');
            }
          })
        }
        else {
          tab.classList.add('active');
          text.textContent = 'Выбрано';
          tabs.forEach((item, i) => {
            if (item === tab) {
              wrappers[i].classList.remove('inactive');
            }
          })
        }
  
        calcAll();
      })
  
      // calc();
    })
  
    let mainObj = {
          countersArr: [],
          costsArr: [],
        };
  
    function makeCalc (wrapperMain) {
      const checkboxMain = wrapperMain.querySelector('input');
  
      function makeSecondZero (wrapper) {
        const checkbox = wrapper.querySelector('input'),
              time = wrapper.querySelector('.js-time'),
              cost = wrapper.querySelector('.js-cost');
  
        checkbox.checked = false;
        time.textContent = 0;
        cost.textContent = '0 ₽';
      }
  
      function makeSecondChecked (wrapper) {
        const checkbox = wrapper.querySelector('input');
  
        checkbox.checked = true;
      }
  
      function makeThirdZero (wrapper) {
        const checkbox = wrapper.querySelector('input');
  
        checkbox.checked = false;
      }
  
      function makeThirdChecked (wrapper) {
        const checkbox = wrapper.querySelector('input');
  
        checkbox.checked = true;
      }
  
      function checkSecond () {
        let counter = 0;
  
        wrapperMain.querySelectorAll('.js-second-item').forEach(item => {
          if (item.querySelector('input').checked) {
            counter += 1;
          }
        });
  
        if (counter) {
          wrapperMain.querySelector('input').checked = true;
        }
        else {
          wrapperMain.querySelector('input').checked = false;
        }
  
  
      }
  
      function checkThird (wrapper) {
        let counter = 0;
  
        wrapper.querySelectorAll('.js-third-item').forEach(item => {
          if (item.querySelector('input').checked) {
            counter += 1;
          }
        });
  
        if (counter) {
          wrapper.querySelector('input').checked = true;
        }
        else {
          wrapper.querySelector('input').checked = false;
        }
  
  
      }
  
      wrapperMain.querySelectorAll('.js-second-item').forEach(wrapper => {
        wrapper.querySelector('input').addEventListener('change', function () {
          list.innerHTML = '';
          inputPick.value = '';
          if (this.checked) {
            wrapper.querySelectorAll('.js-third-item').forEach(item => {
              makeThirdChecked(item);
            });
          }
          else {
            wrapper.querySelectorAll('.js-third-item').forEach(item => {
              makeThirdZero(item);
            });
          }
  
          checkSecond();
          calcAll();
        });
      });
  
      checkboxMain.addEventListener('change', function (event) {
        list.innerHTML = '';
        inputPick.value = '';
  
        if (this.checked) {
          wrapperMain.querySelectorAll('.js-second-item').forEach(item => {
            makeSecondChecked(item);
          });
  
          wrapperMain.querySelectorAll('.js-third-item').forEach(item => {
            makeThirdChecked(item);
          });
        }
        else {
          wrapperMain.querySelector('.js-days').textContent = '0 дней';
          wrapperMain.querySelector('.js-hours').textContent = '0 часов';
          wrapperMain.querySelector('.js-cost').textContent = '0 ₽';
  
          wrapperMain.querySelectorAll('.js-second-item').forEach(item => {
            makeSecondZero(item);
          });
  
          wrapperMain.querySelectorAll('.js-third-item').forEach(item => {
            makeThirdZero(item);
          });
        }
  
        calcAll();
      });
  
      wrapperMain.querySelectorAll('.js-second-item').forEach(wrapper => {
        wrapper.querySelectorAll('.js-third-item').forEach(item => {
          let input = item.querySelector('input');
  
          input.addEventListener('change', event => {
            let checked = input.checked;
            list.innerHTML = '';
            inputPick.value = '';
            if (event.target.closest('.js-one-pick')) {
              wrapper.querySelectorAll('.js-third-item input').forEach(item => {
                if (item.dataset.name === input.dataset.name) {
                  item.checked = false;
                }
              });
              input.checked = checked;
            }
  
            if (event.target.closest('.js-only-several')) {
              wrapper.querySelectorAll('.js-third-item input').forEach(item => {
                if (item.dataset.name === input.dataset.name) {
                  item.checked = checked;
                }
              });
            }
  
            checkThird(wrapper);
            checkSecond();
            calcAll();
          });
  
          // item.querySelector('.js-btn-add').addEventListener('click', event => {
          //   if (input.checked) {
          //   }
          //   else {
          //     input.checked = true;
          //     checkThird(wrapper);
          //     checkSecond();
          //     calcAll();
          //   }
          // })
        });
  
      });
    }
  
    function addToFooter (array, costArray = []) {
      array.forEach((item, i) => {
        list.innerHTML += `
        <li class="footer__item-calc">
          <div class="footer__wrapper-calc">
            ${item}
          </div>
        </li>
        `;
  
        inputPick.value += `${item} - ${costArray[i]}, <br> `;
      });
  
      if (list.querySelectorAll('li').length <= 0) {
        list.classList.add('hidden');
        title.textContent = 'Обратная связь';
        NKH.order = mainCostWrapper.textContent;
        inputCost.value = NKH.order;
      }
      else {
        list.classList.remove('hidden');
        title.innerHTML = 'Вы выбрали услуги на сумму <br>' + mainCostWrapper.textContent;
        NKH.order = mainCostWrapper.textContent;
        inputCost.value = NKH.order;
      }
    }
  
    function calcAll () {
      list.innerHTML = '';
      inputPick.value = '';
  
      wrappers.forEach((wrapper, moduleNum) => {
        calc (wrapper, moduleNum);
      });
    }
  
    function calc (wrapperMain, moduleNum) {
      let mainCounterHours = 0,
          mainCounterCost = 0,
          days = 0,
          counter = 0,
          counterHaveMain = 0,
          counterAllMain = 0,
          arrayMain = [],
          arrayCostMain = [];
  
      wrapperMain.querySelectorAll('.js-second-item').forEach(wrapper => {
        let counterHours = 0,
            counterCost = 0,
            counterHoursAlt = 0,
            counterCostAlt = 0,
            counterAll = 0,
            counterHave = 0,
            array = [],
            arrayCost = [],
            costHelp;
  
        wrapper.querySelectorAll('.js-third-item').forEach(item => {
          if (item.querySelector('input').checked && !item.closest('.inactive')) {
            counterHours += parseFloat(item.querySelector('.js-time').textContent.replace(/\s+/g, ''));
            counterCost += parseFloat(item.querySelector('.js-cost').textContent.replace(/\s+/g, ''));
            counter += 1;
            counterHave += 1;
            array.push(item.querySelector('.js-text').textContent.trim());
            arrayCost.push(item.querySelector('.js-cost').textContent.trim());
          }
          counterAll += 1;
          counterHoursAlt += parseFloat(item.querySelector('.js-time').textContent.replace(/\s+/g, ''));
          counterCostAlt += parseFloat(item.querySelector('.js-cost').textContent.replace(/\s+/g, ''));
        });
  
        counterAllMain += 1;
  
        if (counterHave === counterAll) {
          counterHaveMain += 1;
          arrayMain.push(wrapper.querySelector('.js-text').textContent.trim());
          arrayCostMain.push(wrapper.querySelector('.js-cost').textContent.trim());
        }
        else if (counterHave > 0) {
          addToFooter(array, arrayCost);
        }
  
        wrapper.querySelector('.js-time').textContent = counterHoursAlt;
        counterCost += " ₽";
        counterCost = counterCost.slice(0, counterCost.length - 5) + ' ' + counterCost.slice(counterCost.length - 5, counterCost.length);
        counterCostAlt += " ₽";
        counterCostAlt = counterCostAlt.slice(0, counterCostAlt.length - 5) + ' ' + counterCostAlt.slice(counterCostAlt.length - 5, counterCostAlt.length);
        wrapper.querySelector('.js-cost').textContent = counterCostAlt;
  
        mainCounterHours += counterHours;
        mainCounterCost += parseFloat(counterCost.replace(/\s+/g, ''));
  
        if (!tabs[moduleNum].classList.contains('js-not-count')) {
          costHelp = mainCounterCost + ' ₽'
          tabs[moduleNum].querySelector('.js-value').textContent = costHelp.slice(0, costHelp.length - 5) + ' ' + costHelp.slice(costHelp.length - 5, costHelp.length);
        }
  
      });
  
      mainCounterHours = Math.ceil(mainCounterHours);
      wrapperMain.querySelector('.js-hours').textContent = mainCounterHours + ' ' + declOfNum(mainCounterHours, ['час', 'часа', 'часов']);
  
      days = Math.ceil(mainCounterHours / 8);
      wrapperMain.querySelector('.js-days').textContent = days + ' ' + ' ' + declOfNum(days, ['день', 'дня', 'дней']);
  
      mainCounterCost += " ₽";
      mainCounterCost = mainCounterCost.slice(0, mainCounterCost.length - 5) + ' ' + mainCounterCost.slice(mainCounterCost.length - 5, mainCounterCost.length);
      wrapperMain.querySelector('.js-cost').textContent = mainCounterCost;
  
      wrapperMain.querySelector('.js-count').textContent = counter;
  
      finalCalc ();
  
      if (counterHaveMain === counterAllMain) {
        addToFooter([wrapperMain.querySelector('.js-text').textContent.trim()], [wrapperMain.querySelector('.js-cost').textContent.trim()]);
      }
      else if (counterHaveMain > 0) {
        addToFooter(arrayMain, arrayCostMain);
      }
  
      addToFooter([]);
    }
  
    function finalCalc () {
      let counter = 0,
          cost = 0;
      mainObj.countersArr.forEach((item, i) => {
        counter += +mainObj.countersArr[i].textContent;
        cost += parseFloat(mainObj.costsArr[i].textContent.replace(/\s+/g, ''));
      });
      cost += " ₽";
      cost = cost.slice(0, cost.length - 5) + ' ' + cost.slice(cost.length - 5, cost.length);
      mainCounterWrapper.textContent = counter;
      mainCostWrapper.textContent = cost;
      topCost.textContent = cost;
    }
  
    wrappers.forEach(wrapper => {
      mainObj.countersArr[mainObj.countersArr.length] = wrapper.querySelector('.js-count');
      mainObj.costsArr[mainObj.costsArr.length] = wrapper.querySelector('.js-cost');
      makeCalc(wrapper);
    });
  
    calcAll();
  }
  
  function input () {
    const wrappers = document.querySelectorAll('.js-input');
  
    wrappers.forEach(wrapper => {
      const input = wrapper.querySelector('input');
  
      if (input) {
        input.addEventListener('focus', () => {
          wrapper.classList.add('active');
        });
  
        input.addEventListener('blur', () => {
          if (!input.value.length) {
            wrapper.classList.remove('active');
          }
        })
      } 
      else {
        const textarea = wrapper.querySelector('textarea');
        textarea.value = textarea.value.trim();
        
        textarea.addEventListener('focus', () => {
          wrapper.classList.add('active');
        });
  
        textarea.addEventListener('blur', () => {
          if (!textarea.value.trim().length) {
            wrapper.classList.remove('active');
          }
        })
      }
  
  
      
    })
  }
  const ancors = () => {
    const links = document.querySelectorAll('.js-ancor-link');
  
    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
  
        const elemData = link.getAttribute('href');
        const elem = document.querySelector(`${elemData}`);
        const elemCoords = getCoords(elem);
        const finalCoords = elemCoords.top - 100;
  
        $("html, body").animate({ scrollTop: finalCoords });
      });
    });
  };
  
  const phoneMask = () => {
    $('.js-phone-mask').mask('+7 (000) 000-00-00');
  };
  
  function form () {
      document.getElementById('form').addEventListener('submit', function(event) {
          event.preventDefault();
          setTimeout(() => {
              if (!document.querySelector('.parsley-errors-list li')) {
                  NKH.order = parseFloat(NKH.order.replace(/\s+/g, ''))
                  let ask = document.querySelector('.js-title-footer').textContent.trim() === 'Обратная связь';
                  $.post(
                      'send.php', // адрес обработчика
                      $("#form").serialize(), // отправляемые данные          
                      
                      function(msg) { // получен ответ сервера 
                          document.querySelector('.popup-footer').classList.add('active');
                          NKH.body.classList.add('fixed');
          
                          if (ask) {
                              var goalParams = {
                                  order_price: NKH.order,
                                  currency: "RUB",
                              }
          
                              
                              ym(65567878,'reachGoal','send_form_without_calculator', goalParams);
          
                              gtag('event', 'generate_lead', {
                                  'event_label': 'send_form_without_calculator',
                                  'value': NKH.order
                              });
          
                          }
                          else {
                              var goalParams = {
                                  order_price: NKH.order,
                                  currency: "RUB",
                              }
          
                              ym(65567878,'reachGoal','send_form_with_calculator', goalParams)
                          
                              gtag('event', 'generate_lead', {
                                  'event_label': 'send_form_with_calculator',
                                  'value': NKH.order
                              });
                          }   
                      }
                  );
                  return false;
              }
          }, 500)
      });
  }
  function addFile () {
    let item = document.querySelector('.js-file'),
        input = item.querySelector('input'),
        span = item.querySelector('span');
  
    item.addEventListener('change', event => {
      if (event.srcElement.files.length === 0) {
        span.textContent = 'Добавить файл'
      }
      else if (event.srcElement.files.length === 1) {
        span.textContent = event.srcElement.files[0].name;
      }
      else {
        span.textContent = `Вы добавили ${event.srcElement.files.length} файлов`
      }
    })
  }
  function popupPhoto () {
    const itemsSlider = document.querySelectorAll('.js-show-scale'),
          itemsAccordion = document.querySelectorAll('.js-show-accordion'),
          popup = document.querySelector('.js-popup-scale'),
          imgPopup = popup.querySelector('img'),
          textPopup = popup.querySelector('span');
  
    itemsSlider.forEach(item => {
      const img = item.querySelector('img'),
            text = item.querySelector('.js-text');
  
  
      showPopup(img, img.src, text);
    });
  
    itemsAccordion.forEach(item => {
      if (!item.classList.contains('disabled')) {
        showPopup(item, item.dataset.img, false)
      }
    });
  
    function showPopup (btn, src, text) {
      let isMoved = false;
      btn.addEventListener('mousedown', event => {
          isMoved = false;
      });
      btn.addEventListener('mousemove', event => {
          isMoved = true;
      });
      btn.addEventListener('mouseup', event => {
        if (!isMoved) {
            imgPopup.src = src;
            if (text) {
                textPopup.textContent = text.textContent;
            } else {
                textPopup.textContent = '';
            }
  
            popup.classList.add('active');
            NKH.body.classList.add('fixed');
        }
      });
    }
  
    popup.addEventListener('click', event => {
      popup.classList.remove('active');
      NKH.body.classList.remove('fixed');
      imgPopup.src = '';
    })
  }
  
  function popup () {
    let popup = document.querySelectorAll('.js-popup');
  
    popup.forEach(item => {
      item.addEventListener('click', event => {
        if (event.target.closest('.js-close') || !event.target.closest('.popup__wrapper')) {
          item.classList.remove('active');
          NKH.body.classList.remove('fixed');
        }
      });
    });
  }
  function showArrow () {
    const line = document.getElementById('why'),
          arrow = document.querySelector('.js-arrow-up');
  
    window.addEventListener('scroll', event => {
  
      if (line.offsetTop < window.pageYOffset + 200) {
        arrow.classList.add('active');
      }
      else {
        arrow.classList.remove('active');
      }
    })
  }
  function jsSeveral () {
    const items = document.querySelectorAll('.js-only-several>input');
  
    let name = items[0].dataset.name,
        i = 0;
  
    items.forEach((item, j) => {
      i++;
  
      if (item.dataset.name !== name) {
        i = 0;
        name = item.dataset.name;
      }
  
      if (i !== 0 && j !== 0) {
        item.closest('label').style.display = 'none';
      }
    })
  }
  
  function changeColor () {
    let item = document.querySelector('.js-change-color'),
        line = document.querySelector('.js-check-line');
  
    function checkLine () {
      if (line.clientHeight - item.clientHeight === item.offsetTop) {
        item.classList.add('active');
      }
      else {
        item.classList.remove('active');
      }
    }
  
    document.addEventListener('scroll', checkLine);
  
    checkLine();
  }
  function accordionPopup () {
    let wrappers = document.querySelectorAll('.js-popup-wrapper');
  
    wrappers.forEach(wrapper => {
      wrapper.addEventListener('mouseenter', event => {
        let item = wrapper.querySelector('.js-popup');
        
        if (event.screenY - 150 < item.offsetHeight) {
          wrapper.classList.add('down');
        }
        else {
          wrapper.classList.remove('down');
        }
      })
    })
  };
  const headerScroll = () => {
    window.addEventListener('scroll', (e) => {
      const scrollTop = window.pageYOffset;
      const isActive = scrollTop > 50;
      const header = document.querySelector('.js-page-header');
  
      if(isActive){
        header.classList.add('fixed');
      }else{
        header.classList.remove('fixed');
      }
    });
  };
  
  const parallax = () => {
    var p = new Parallax('.parallax').init()
  };
  
  const wow = () => {
      const wowInit = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: false,
        live: true,
      }
    );
  
    wowInit.init();
  };
  
  const moduleInitNumber = () => {
    const values =  document.querySelectorAll('.js-value');
  
    values.forEach((value) => {
      const dataPrice = value.getAttribute('data-price');
  
      if(dataPrice){
        value.innerText = dataPrice;
      }
    });
  };
  
  const NKH = {};
  NKH.body = document.querySelector('body');
  NKH.ESC_CODE = 27;
  NKH.siteContent = document.querySelector('.site-content');
  NKH.footer = document.querySelector('.page-footer');
  NKH.isIe11 = !!window.MSInputMethodContext && !!document.documentMode;
  
  
  if(NKH.isIe11){
    NKH.body.classList.add('ie11');
  }
  
  var getSiblings = function (elem) {
    var siblings = [];
    var sibling = elem.parentNode.firstChild;
  
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling
    }
  
    return siblings;
  
  };
  
  function top_walker (node, test_func, last_parent) {
  	while ( node && node !== last_parent ) {
  		if ( test_func(node) ) {
  			return node;
  		}
  		node = node.parentNode;
  	}
  }
  
  function getCoords(elem) {
    let box = elem.getBoundingClientRect();
  
    return {
      top: box.top + pageYOffset,
      left: box.left + pageXOffset
    };
  }
  
  const onPageRdy = () => {
    // utility
    calculator();
    accordion();
    input();
    phoneMask();
    parallax();
    // addFile();
    wow();
    changeColor();
    // accordionPopup();
    popupPhoto();
    popup();
    showArrow();
    jsSeveral();
    form();
  
    // specific
    ancors();
    headerScroll();
    moduleInitNumber();
  
    // sliders
    carousel();
  };
  
  onPageRdy();
  
});
